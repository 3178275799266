import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import MemberFormOnlinePopup from "./MemberFormOnlinePopup";
import MemberFormOnlineSelectTheme from "./MemberFormOnlineSelectTheme";
import { Services, Alert } from "./../../Service/Services";
import { Redirect } from "react-router-dom";

var HtmlToReactParser = require("html-to-react").Parser;
const $ = window.$;

class MemberFormOnlineStep1 extends Component {
  constructor(props) {
    super(props);
    this.import_file_element = React.createRef();
  }

  state = {
    // data: {
    // 	members: [],
    // },
    url: {
      download: axios.defaults.baseURL + "/member/download?" + Date.now(),
      upload: axios.defaults.baseURL + "/member/import",
    },
    selected_member: null,
    datasource: {
      payment_period: {},
    },
    theme_editable: true,
  };

  deleteMember = (key) => {
    Alert.confirm("คุณแน่ใจไหมที่จะลบข้อมูล").then((res) => {
      if (res === true) {
        let members = [...this.props.data];

        members.splice(key, 1);

        this.props.onChange(members);
      }
    });
  };

  editTheme = (key) => {
    let selected_member = [...this.props.data][key];
    selected_member.row_index = key;

    this.setState({ selected_member });
  };

  editMember = (key) => {
    let selected_member = [...this.props.data][key];
    selected_member.row_index = key;

    this.setState({ selected_member });
  };

  openFile = (e) => {
    $(this.import_file_element.current)
      .trigger("click")
      .on("change", (e) => {
        this.props.onLoading(true);
        const url = this.state.url.upload; //'member/import';
        const params = {
          url,
          params: {
            import: e.target.files[0],
            id: null,
            is_online: "1",
          },
        };

        const payment_period = this.state.datasource.payment_period;

        Services.submitRequestWithFile(params)
          .then((res) => {
            const data = res.data;

            let current_row_index = this.props.data.length;

            const members = data.map((item, key) => {
              let member = {
                id: "",
                prefix_id: item.prefix_id || item.prefix_text,
                first_name: item.first_name,
                last_name: item.last_name,
                identity_number: item.identity_number,
                career_id: item.career_id || item.career_text,
                career_number: item.career_number,
                nurse_number: item.nurse_number,
                email: item.email,
                row_index: current_row_index++,
              };

              if (
                payment_period &&
                payment_period.online_free !== null &&
                payment_period.online_selected === null
              ) {
                member.online_register_type = "f";
              }

              return member;
            });

            const data_members = [...this.props.data, ...members];

            // this.setState({ data: data_members });

            this.props.onChange(data_members);
            this.props.onLoading(false);
          })
          .catch((errors) => {
            Alert.error(errors.join("\n"), "มีข้อผิดพลาด");

            $(this.import_file_element.current).val("");

            this.props.onLoading(false);
          });
      });
  };

  onSubmitMember = (member) => {
    let members = [...this.props.data];

    if (member.row_index !== "") {
      members[member.row_index] = member;
    } else {
      const payment_period = this.state.datasource.payment_period;
      if (
        payment_period &&
        payment_period.online_free !== null &&
        payment_period.online_selected === null
      ) {
        member.online_register_type = "f";
      }

      members.push(member);
    }

    this.setState({ selected_member: null });

    this.props.onChange(members);
  };

  onSubmitTheme = (theme) => {
    console.log(theme);

    let members = [...this.props.data];

    if (theme.row_index !== "") {
      members[theme.row_index].section_online_id = theme.section_online_id;
      members[theme.row_index].section_online_name = theme.section_online_name;
      members[theme.row_index].online_register_type =
        theme.online_register_type;
    }

    this.setState({ selected_member: null });

    this.props.onChange(members);
  };

  componentDidMount = () => {
    this.getAPIDatasource();
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "payment_period" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const payment_period = res.data.payment_period;
        const datasource = {
          payment_period: payment_period,
        };

        const theme_editable = !(
          payment_period.online_free !== null &&
          payment_period.online_selected === null
        );

        this.setState({ datasource, theme_editable });
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  componentDidUpdate = (props, nextProps) => {
    // console.log('componentDidUpdate', props, nextProps);
  };

  componentWillReceiveProps = (nextProps) => {};

  shouldComponentUpdate = (props, nextProps) => {
    // console.log('shouldComponentUpdate', props, nextProps, props.data !== nextProps.data);

    return true;
  };

  render() {
    let buttons;

    if (this.props.editable === true) {
      buttons = (
        <div>
          <button
            type="button"
            className="btn btn-primary pull-right mx-2"
            data-toggle="modal"
            data-target="#memberModal"
          >
            <i className="fa fa-plus"></i> เพิ่มสมาชิก
          </button>
          <div className="dropdown pull-right">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              นำข้อมูลเข้า
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href={this.state.url.download}>
                ตัวอย่างข้อมูลนำเข้า
              </a>
              <a className="dropdown-item" onClick={this.openFile}>
                นำข้อมูลเข้า
              </a>
              <input
                type="file"
                className="d-none"
                accept=".xlx,.xlsx"
                ref={this.import_file_element}
              ></input>
            </div>
          </div>
        </div>
      );
    }

    const theme_editable = this.state.theme_editable;
    const members = this.props.data.map((item, key) => {
      let row_buttons, theme_buttons, theme_buttons_name;

      if (this.props.editable === true) {
        row_buttons = (
          <div className="mb-2">
            <a
              className="btn btn-secondary btn-sm"
              onClick={(e) => this.editMember(key)}
              data-toggle="modal"
              data-target="#memberModal"
            >
              <i className="fa fa-pencil"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="btn btn-danger btn-sm"
              onClick={(e) => this.deleteMember(key)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        );

        if (theme_editable) {
          if (item.online_register_type) {
            if (item.section_online_name) {
              theme_buttons_name = (
                <a
                  className="btn btn-success font-weight-bold text-white"
                  onClick={(e) => this.editTheme(key)}
                  data-toggle="modal"
                  data-target="#themeModal"
                >
                  {item.section_online_name}
                </a>
              );
            } else {
              theme_buttons_name = (
                <a
                  className="btn btn-primary font-weight-bold text-white"
                  onClick={(e) => this.editTheme(key)}
                  data-toggle="modal"
                  data-target="#themeModal"
                >
                  Free Theme
                </a>
              );
            }
          } else {
            theme_buttons_name = (
              <a
                className="btn btn-link btn-sm text-danger"
                onClick={(e) => this.editMember(key)}
                data-toggle="modal"
                data-target="#themeModal"
              >
                เลือก Theme
              </a>
            );
          }
        } else {
          if (item.online_register_type) {
            if (item.section_online_name) {
              theme_buttons_name = (
                <span className="font-weight-bold">
                  {item.section_online_name}
                </span>
              );
            } else {
              theme_buttons_name = (
                <span className="font-weight-bold">Free Theme</span>
              );
            }
          }
        }

        theme_buttons = (
          <div className="mb-2">
            <u>{theme_buttons_name}</u>
          </div>
        );
      } else {
        row_buttons = (
          <div>
            <a
              className="btn btn-info btn-sm"
              onClick={(e) => this.editTheme(key)}
              data-toggle="modal"
              data-target="#memberModal"
            >
              <i className="fa fa-eye"></i>
            </a>
            &nbsp;&nbsp;
          </div>
        );

        if (item.online_register_type) {
          if (item.section_online_name) {
            theme_buttons = (
              <span className="font-weight-bold">
                {item.section_online_name}
              </span>
            );
          } else {
            theme_buttons = (
              <span className="font-weight-bold">Free Theme</span>
            );
          }
        }
      }

      return (
        <tr key={key}>
          <td>
            {item.first_name} {item.last_name}
          </td>
          <td className="text-center">{item.identity_number}</td>
          <td className="text-center">{theme_buttons}</td>
          <td className="text-center text-white">{row_buttons}</td>
        </tr>
      );
    });

    return (
      <div className="tab-pane active" role="tabpanel" id="step1">
        <MemberFormOnlinePopup
          editable={this.props.editable}
          onSubmitMember={this.onSubmitMember}
          member={this.state.selected_member}
        ></MemberFormOnlinePopup>
        <MemberFormOnlineSelectTheme
          is_early_bird={this.props.is_early_bird}
          editable={this.props.editable}
          onSubmitTheme={this.onSubmitTheme}
          member={this.state.selected_member}
        ></MemberFormOnlineSelectTheme>
        <h3>ข้อมูลสมาชิก</h3>
        {/* <p><small className='text-mute'>ข้อมูลสมาชิก</small></p> */}
        {buttons}
        <div className="text-left p-5">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">ชื่อ - นามสกุล</th>
                <th className="text-center">เลขที่บัตรประชาชน</th>
                <th className="text-center">Theme</th>
                <th className="text-center">#</th>
              </tr>
            </thead>
            <tbody>{members}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

MemberFormOnlineStep1.defaultProps = {
  data: [],
  editable: false,
  onLoading: {},
  is_early_bird: false,
};

export default MemberFormOnlineStep1;
